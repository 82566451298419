import React from "react"
import * as S from "./molecule-section.styles"
import * as Logo from "../CyclesSection/cycles-section.styles"
import { Container } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { Parallax } from "react-scroll-parallax"

const MoleculeSection = ({ descriptionMolecule }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      molecule: file(relativePath: { eq: "about-molecule.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <S.Wrapper>
      <Container sx={{ position: "relative" }}>
        {descriptionMolecule && (
          <S.Description>
            <b>Proton</b>
            <Logo.ProtonLogo />
            allows PostEra to do faster drug discovery, find more optimized drug
            candidates, and develop better cures for patients
          </S.Description>
        )}
        <S.MoleculeWrapper>
          <Parallax speed={10}>
            <S.Molecule img={staticQuery.molecule} alt="molecule-image" />
          </Parallax>
        </S.MoleculeWrapper>
        <div id="publications" />
      </Container>
    </S.Wrapper>
  )
}

export default MoleculeSection
